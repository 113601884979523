.ResultTable {
	width: 100%;
	background-color: rgb(233, 195, 195);
	border-collapse: collapse;

	thead {
		th {
			padding: 10px;
			text-align: center;
			background-color: skyblue;

			@media (max-width: 1200px) {
				padding: 5px;
			}

			svg {
				vertical-align: -3px;
			}
		}
	}

	.CourseRow {
		&:nth-of-type(odd) {
			background-color: #e7e7e7;
		}
		&:nth-of-type(even) {
			background-color: #e0e0e0;
		}

		td {
			padding: 5px;
			text-align: center;
			border-bottom: #ccc 1px solid;
		}

		.hovered-badges {
			right: 0;
			position: absolute;

			.badge {
				margin: 2px;
			}
		}

		&:hover {
			filter: drop-shadow(0px 5px 5px #aaa);

			.hovered-badges {
				opacity: 0.35;
			}
		}
	}
}
